<template>
  <!--新增对话框-->
  <el-dialog :title="editForm.exchangeGoodsId ? '编辑' : '新增'" :visible.sync="dialogVisible" width="60%"
    :close-on-click-modal="false" :before-close="handleClose">
    <el-form :model="editForm" :rules="editFormRules" ref="editForm">
      <el-form-item label="商品类型" label-width="100px" prop="exchangeGoodsType" >
        <el-select v-model="editForm.exchangeGoodsType" clearable placeholder="商品类型" style="width: 100%;" @change="ChangeGoodType">
          <el-option v-for="(item, index) in TypeList" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称" prop="exchangeGoodsName" label-width="100px">
        <el-input v-model="editForm.exchangeGoodsName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="商品分类" label-width="100px" prop="exchangeGoodsType">
        <el-select v-model="editForm.exchangeGoodsUse" clearable placeholder="商品分类" style="width: 100%;">
          <el-option v-for="(item, index) in GoodsUseList" :key="index" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠券" label-width="100px" prop="couponsId" v-if="editForm.exchangeGoodsType==3">
        <el-select v-model="editForm.couponsId" clearable placeholder="优惠券" style="width: 100%;">
          <el-option v-for="(item, index) in couponList" :key="index" :label="item.couponsName"
            :value="item.couponsId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品照片" prop="exchangeGoodsPic" label-width="100px">
        <vpload-img-two :limit="1" v-bind:file-list="fileList" :type="1" @submitImg="getImgList"></vpload-img-two>
      </el-form-item>
      <el-form-item label="所需积分" prop="requiredPoints" label-width="100px">
        <el-input v-model="editForm.requiredPoints" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="实际价格" prop="realValue" label-width="100px">
        <el-input v-model="editForm.realValue" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="商品数量" prop="exchangeGoodsCount" label-width="100px">
        <el-input v-model="editForm.exchangeGoodsCount" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm('editForm')">取 消</el-button>
      <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import VploadImgTwo from "../inc/VploadImgTwo";
import TinymceEditor from "../inc/TinymceEditor";
export default {
  name: "AddUpdateVehicletype",
  components: {
    VploadImgTwo,TinymceEditor
  },
  data() {
    return {
      TypeList: [
        {
          value: 1, name: '虚拟商品'
        }, {
          value: 2, name: '实体商品'
        }, {
          value: 3, name: '优惠券'
        }
      ],
      GoodsUseList: [
        {
          value: 1, name: '两端'
        }, {
          value: 2, name: '货主端'
        },
        {
          value: 3, name: '司机端'
        }
      ],
      couponList:[],
      editForm: {},
      avatar: '',
      fileList: [],
      dialogVisible: false,
      ispass: true,
      editFormRules: {
        exchangeGoodsName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        exchangeGoodsType: [
          { required: true, message: '请选择商品类型', trigger: 'change' }
        ],
        exchangeGoodsUse: [
          { required: true, message: '请选择商品分类', trigger: 'change' }
        ],
        realValue: [
          { required: true, message: '请输入实际价格', trigger: 'change' }
        ],
        requiredPoints: [
          { required: true, message: '请输入所需积分', trigger: 'change' }
        ],
        exchangeGoodsCount: [
          { required: true, message: '请输入商品数量', trigger: 'change' }
        ],
        couponsId: [
          { required: true, message: '请输入选择优惠券', trigger: 'change' }
        ],
        exchangeGoodsPic: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
      },
    }
  },

  methods: {
    ChangeGoodType(){
      if(this.editForm.exchangeGoodsType==3){
        this.$axios.post('/admin/exchangegoods/getFreeCoupons').then(res => {
            this.couponList=res.data
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
      this.fileList = []
    },
    handleClose() {
      this.resetForm('editForm')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/admin/exchangegoods/' + (this.editForm.exchangeGoodsId ? 'updateExchangeGoods' : 'addExchangeGoods'), this.editForm)
            .then(res => {

              this.$message({
                showClose: true,
                message: '恭喜你，操作成功',
                type: 'success',
                onClose: () => {
                  this.$emit('refreshDataList')
                }
              });
              this.dialogVisible = false
              this.resetForm(formName)
            })
        } else {
          return false;
        }
      });
    },
    getImgList(url, add) {
      if (add == 1) {
        this.editForm.exchangeGoodsPic = url
      } else if (add == 0) {
        this.editForm.exchangeGoodsPic = '';
      }
    },
    init(id) {
      this.dialogVisible = true;
      if (id != null && id != '' && id > 0) {
        this.$axios.post('/admin/exchangegoods/getExchangeGoodsInfo', { exchangeGoodsId: id }).then(res => {
          this.editForm = res.data
          if (res.data.exchangeGoodsPic) {
            var filess = { url: res.data.exchangeGoodsPic }
            this.fileList.push(filess)
          }
        })
      }
    },

  }
}
</script>

<style scoped>
.dialog-footer{
  text-align: center !important;
}</style>
