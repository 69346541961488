import { render, staticRenderFns } from "./shopEdit.vue?vue&type=template&id=fb13c772&scoped=true"
import script from "./shopEdit.vue?vue&type=script&lang=js"
export * from "./shopEdit.vue?vue&type=script&lang=js"
import style0 from "./shopEdit.vue?vue&type=style&index=0&id=fb13c772&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb13c772",
  null
  
)

export default component.exports