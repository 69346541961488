<template>
  <div>
    <el-form :inline="true">
      <el-form-item label="商品名称" label-width="100px">
        <el-input v-model="data.exchangeGoodsName" placeholder="商品名称" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="商品类型" label-width="100px">
        <el-select v-model="data.exchangeGoodsType" clearable placeholder="商品类型">
          <el-option v-for="(item, index) in TypeList" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品分类" label-width="100px">
        <el-select v-model="data.exchangeGoodsUse" clearable placeholder="商品分类">
          <el-option v-for="(item, index) in GoodsUseList" :key="index" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="data.pageNo = 1; getgetBillPage();" type="success">搜索</el-button>
        <el-button @click="Edit(null)" type="primary">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
      @selection-change="handleSelectionChange">
      <el-table-column prop="exchangeGoodsName" align="center" min-width="120" label="商品名称">
      </el-table-column>
      <el-table-column prop="requiredPoints" align="center" width="100" label="所需积分">
      </el-table-column>
      <el-table-column prop="realValue" align="center" width="100" label="实际价格">
      </el-table-column>
      <el-table-column prop="remainingQuantity" align="center" width="100" label="剩余数量">
      </el-table-column>
      <el-table-column prop="exchangeGoodsPic" align="center" width="120" label="商品图片">
        <template slot-scope="{row}">
          <el-image style="width: 40px; height: 40px" :src="row.exchangeGoodsPic" fit="scale-down"
            :preview-src-list="row.exchangeGoodsPic.split(',')">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="exchangeGoodsType" align="center" width="120" label="商品类型">
        <template slot-scope="{row}">
          <span v-if='row.exchangeGoodsType == 1'>虚拟商品</span>
          <span v-if='row.exchangeGoodsType == 2'>实体商品</span>
          <span v-if='row.exchangeGoodsType == 3'>优惠券</span>
        </template>
      </el-table-column>
      <el-table-column prop="exchangeGoodsUse" align="center" width="120" label="商品分类">
        <template slot-scope="{row}">
          <span v-if='row.exchangeGoodsUse == 1'>两端</span>
          <span v-if='row.exchangeGoodsUse == 2'>货主端</span>
          <span v-if='row.exchangeGoodsUse == 3'>司机端</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdTime" align="center" width="180" label="创建时间">
        <template slot-scope="{row}">
          <span v-if="row.createdTime"> {{ format(new Date(row.createdTime), 'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="icon" align="center" width="240" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="Detail(scope.row.exchangeGoodsId)">详情</el-button>
          <el-button type="success" plain @click="Edit(scope.row.exchangeGoodsId)">编辑</el-button>
          <template>
            <el-popconfirm title="确定删除该商品吗？" @confirm="delHandle(scope.row.exchangeGoodsId)">
              <el-button type="danger" plain slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="data.pageNo"
      :page-size="data.PageSize" :total="total">
    </el-pagination>
    <Edit ref="refEdit" v-if="visible" @refreshDataList="data.pageNo = 1; getgetBillPage();"></Edit>
    <Detail ref="refdetail" v-if="visibledetail"></Detail>
  </div>
</template>

<script>
import Edit from './shopEdit.vue'
import Detail from './listDetail.vue'

export default {
  name: "CommonExplain",
  components: {
    Edit, Detail
  },
  data() {
    return {
      visibledetail: false,
      total: 0,
      visible: false,
      TypeList: [
        {
          value: 1, name: '虚拟商品'
        }, {
          value: 2, name: '实体商品'
        }, {
          value: 3, name: '优惠券'
        }
      ],
      GoodsUseList: [
        {
          value: 1, name: '两端'
        }, {
          value: 2, name: '货主端'
        },
        {
          value: 3, name: '司机端'
        }
      ],
      tableData: [],
      mapisible: false,
      data: {
        exchangeGoodsName: "",
        exchangeGoodsType: "",
        exchangeGoodsUse: '',
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.getgetBillPage();
  },
  methods: {
    format(date, fmt) {
      var o = {
        "M+": date.getMonth() + 1,                 //月份
        "d+": date.getDate(),                    //日
        "h+": date.getHours(),                   //小时
        "m+": date.getMinutes(),                 //分
        "s+": date.getSeconds(),                 //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()             //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
      }
      return fmt;
    },
    handleSizeChange(val) {
      this.data.pageSize = val
      this.data.pageNo = 1
      this.getgetBillPage()
    },
    handleCurrentChange(val) {
      this.data.pageNo = val
      this.getgetBillPage()
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.delBtlStatu = val.length == 0
    },
    getgetBillPage() {
      this.$axios.post("/admin/exchangegoods/getExchangeGoodsPage", this.data).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    delHandle(id) {
      var that = this
      that.$axios.post("/admin/exchangegoods/deleteExchangeGoods", { exchangeGoodsId: id }).then(res => {
        that.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            that.getgetBillPage()
          }
        });
      })
    },
    Detail(id) {
      this.visibledetail = true;
      this.$nextTick(() => {
        this.$refs.refdetail.init(id);
      })
    },
    Edit(id) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.refEdit.init(id);
      })
    },
  }
}
</script>

<style scoped>
.el-button {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  margin-right: 10px;
}
</style>
